
function pxToRem(value) {
  return `${value / 16}rem`;
}

// function responsiveFontSizes({ sm, md, lg }) {
//   return {
//     "@media (min-width:600px)": {
//       fontSize: pxToRem(sm),
//     },
//     "@media (min-width:900px)": {
//       fontSize: pxToRem(md),
//     },
//     "@media (min-width:1200px)": {
//       fontSize: pxToRem(lg),
//     },
//   };
// }

export const typography = {
  fontFamily: [
    // 'Roboto',
    'Josefin Sans'
  ].join(','),


  // fontWeightRegular: 400,
  // fontWeightMedium: 600,
  // fontWeightBold: 700,
  h1: {
    fontWeight: 100,
    lineHeight: 1.5,
    fontSize: "52pt",
  },
  h2: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: "26pt",
  },
  h5: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },

  body1: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: '16pt',
  },
  body2: {
    fontWeight: 300,
    lineHeight: 1.5,
    fontSize: '12pt',
  },
  subtitle1: {
    fontWeight: 200,
    lineHeight: 1.5,
    // letterSpacing: '0.06em',
    fontSize: '10pt',
  },
  subtitle2: {
    fontWeight: 400,
    // lineHeight: 1.5,
    fontSize: '7pt',
  },
  link1: {
    lineHeight: 1.5,
    fontSize: 16,
  },
  link2: {
    lineHeight: 1.5,
    fontSize: 14,
  },
  caption: {
    lineHeight: 2,
    fontSize: "6pt",
  },

  button: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    textTransform: 'uppercase',
  },
  button1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: "14px",
    textTransform: 'uppercase',
  },
  solutionTitle: {
    fontWeight: 100,
    lineHeight: 1.1,
    fontSize: "48pt",
    fontFamily:'Josefin Sans'
  },
  solutionContent: {
    fontWeight: 100,
    lineHeight: 1.25,
    fontSize: "16pt",
    fontFamily:'Josefin Sans'
  },

  featureTitle: {
    fontWeight: 100,
    lineHeight: 1.1,
    fontSize: "31pt",
    fontFamily:'Josefin Sans'
  },

  featureSubtitle: {
    fontWeight: 200,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    fontFamily:'Josefin Sans'
    //fontSize: "14pt",
  },

  featureList: {
    fontWeight: 100,
    lineHeight: 1.5,
    fontSize: "16pt",
    fontFamily:'Josefin Sans'
  },
  cardTitle:{
    fontWeight: 400,
    lineHeight: 1.0,
    fontSize: "14pt",
    color:'#ffffff',
    fontFamily:'Josefin Sans'
  },
  cardSubtitle:{
    fontWeight: 100,
    lineHeight: "90%",
    fontSize: "8pt",
    color:'#ffffff',
    fontFamily:'Josefin Sans'
  },
};


const HeroSectionData = {
    title: "Digital Shop",
    description: "Mobile based Platform Solution empowering Vendors to launch their brand specific app allowing them to operate the complete sales and order tracking for the orders placed by customers",
    imgSrc: "/assets/img/digitalshop.png"    
}

const ShopSectionData = [
    {
        title: "Take your business Online",
        subtitle: "Digitize Your Shop",
        features: [
            "Get online with your dedicated mobile app",
            "Upload products and pricing to start selling in no time",
            "Invite your customers to your dedicated app"
        ],
        imgSrc: "/assets/img/digital_ecommerce.png",
        imgLeft: true
    },
    {
        title: "Content Rich Cataloging",
        subtitle: "Manage",
        features: [
            "Flexibility to define your product information the way it business needs",
            "Manage your products, pricing and inventory",
            "Simplified product search",
            "Keep customers informed about new products and promotions"
        ],
        imgSrc: "/assets/img/digital_catalog.png",
        imgLeft: false
    },
    {
        title: "Flexibility to operate on fly",
        subtitle: "Operate",
        features: [
            "Use the app as your POS for sales in Physical Store",
            "View and process orders placed",
            "Update the order status for tracking",
            "Automatic Invoice creation",
            "Generate Quote (coming soon)"
        ],
        imgSrc: "/assets/img/digital_catalog.png",
        imgLeft: true
    },
    {
        title: "Track Business Performance & Strategize",
        subtitle: "Business Intelligence",
        features: [
            "Insightful Dashboard to assess performance",
            "Detailed Reports to for deeper analysis",
            "Manage your customer information"
        ],
        imgSrc: "/assets/img/stay_connected.png",
        imgLeft: false
    },

]


export { ShopSectionData, HeroSectionData };
import React from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import Main from "../../layout/Main";

export default function ReturnPolicy() {
  return (
    <Main>
      <Container>
        <Box pt={20} sx={{ flexGrow: 1 }}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h3" color="white">
                Return Policy
              </Typography>
              <Typography color="white">Last updated: Mar 31, 2024</Typography>
              <br /> <br />
              <Typography color="white" variant="body">
                MINDSCLIK through its platform MPERITO provides ability for its customers to launch their branded ecommerce application on web and mobile. As part of the solution few of its customers (businesses) can leverage MINDSCLIK payment gateway to collect money on the purchases made end customer.
              </Typography>
              <br />
              <Typography color="white" variant="body">
                The following Return Policy is applicable to the Customers or Businesses that are leveraging the MPERITO platform along with its payment gateway:

              </Typography>
              <br /> <br />

              <Typography color="white" variant="h4">
                Eligibility for Returns
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Any consumable products purchased through the branded app on MPERITO platform are not eligible for return
                </li>
              </Typography>
              <br />

              <br />
              <Typography color="white" variant="body">
                <li>
                  Each of the Businesses will be defining their return policies
                </li>
              </Typography>
              <br />

              <br />
              <Typography color="white" variant="body">
                <li>
                  Products purchased through the MPERITO platform are eligible for return within 15 days from the date of delivery.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  To be eligible for a return, items must be unused, in the same condition as received, and in the original packaging.
                </li>
              </Typography>
              <br />


              <br /> <br />

              <Typography color="white" variant="h4">
                Return Process
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Customers must contact our customer support team to initiate a return request.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Once the return request is approved, customers will be provided with instructions on how to return the item.
                </li>
              </Typography>
              <br />


              <Typography color="white" variant="h4">
                Return Shipping
              </Typography>
              <br />


              <Typography color="white" variant="body">
                <li>
                  Customers are responsible for the cost of return shipping unless the return is due to a mistake on our part (e.g., wrong item shipped, defective product).
                </li>
              </Typography>
              <br />
              <br />
              <Typography color="white" variant="h4">
                Refunds
              </Typography>
              <br />

              <Typography color="white" variant="body">
                <li>
                  Customers are responsible for providing accurate shipping addresses. MINDSCLIK and MPERITO are not liable for any delays or non-delivery due to incorrect address information provided by the customer.
                </li>
              </Typography>
              <br />
              <br />

              <Typography color="white" variant="h4">
                Delivery Issues
              </Typography>
              <br />

              <Typography color="white" variant="body">
                <li>
                  Refunds will be issued to the original payment method within 15 business days of receiving the returned item.
                </li>
              </Typography>
              <Typography color="white" variant="body">
                <li>
                  Shipping costs are non-refundable unless the return is due to a mistake on our part.
                </li>
              </Typography>
              <br />


              <br />
              <Typography component="h1" variant="h4" color="white">
                Changes to this Return Policy
              </Typography>
              <Typography component={"p"} color="white" variant="body">
                We may update Our Return Policy from time to time. We will
                notify You of any changes by posting the new Return Policy on
                this page.
              </Typography>
              <br />
              <Typography component={"p"} color="white" variant="body">
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Return Policy.
              </Typography>
              <br />
              <Typography component={"p"} color="white" variant="body">
                You are advised to review this Return Policy periodically for
                any changes. Changes to this Return Policy are effective when
                they are posted on this page.
              </Typography>
              <br />
              <Typography component="h1" variant="h4" color="white">
                Contact Us
              </Typography>
              <Typography component={"p"} color="white" variant="body">
                If you have any questions about this Return Policy, You can
                contact us:
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  By email:{" "}
                  <a
                    href="/cdn-cgi/l/email-protection"
                    class="__cf_email__"
                    data-cfemail="bdcec8cdcdd2cfc9fdd0d4d3d9ceded1d4d693ded2d0"
                    style={{ color: "#00c3ff" }}
                  >
                    support@mindsclik.com
                  </a>
                </li>
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Main>
  );
}

import { React } from "react";
import { Grid, Box, Typography } from "@mui/material";

const brifcase = [
  {
    id: 0,
    title: "",
    subtitle: "KEY FEATURES",
    features: [
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ibh euismod tincidunt ut laoreet dolore",
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ibh euismod tincidunt ut laoreet dolore",
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ibh euismod tincidunt ut laoreet dolore",
    ],
  },
];

function Briefcase({ title, subtitle, features }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textTransform="capitalize"
                >
                  {subtitle}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {features?.map((feature) => (
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={1}>
                        <img
                          alt="solution"
                          src="assets/icons/handarrow.png"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          // key={item}
                          variant="body1"
                          color="text.main"
                          textTransform="capitalize"
                        >
                          {feature}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Briefcase;

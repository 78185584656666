import {React} from 'react';
import { Route, Routes } from "react-router-dom";

import viewsRoutes from './views/routes';
import RedirectComponent from './components/Redirect';

const routes = () => {
    return (
        <Routes>
        {
          viewsRoutes.map((item,i) => (
            <Route key={i} path={item.path}  element = {item.element} />
          ))
        }
        <Route path="/:appname/:encodeded?" element={<RedirectComponent />} />
      </Routes>
    );
  };


export default routes;

import React from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import SolidSolution from "./components/SolidSolution/solidSolution";

const Soloution = () => {
  return (
    <Grid>
      <Container>
        <Grid>
        <Grid sx={{display:{xs:'none' ,sm:'none',md:'flex'} }}>
        <Box sx={{ flexGrow: 1 ,}}>
          <Grid container spacing={"0px"}>
          <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <SolidSolution />
            </Grid>
            <Grid item xs={7} >
              <img
                alt="solution"
                src="assets/img/solution_bg.png"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Box>
        </Grid>
        <Grid sx={{display:{xs:'content' ,sm:'content',md:'none'}}}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={"0px"} >
            <Grid item xs={8} zIndex={3} >
              <SolidSolution />
            </Grid>
            <Grid item xs={4} ></Grid>
            <Grid item xs={2} ></Grid>
            <Grid item xs={8} right={2} zIndex={2}  position={'absolute'} >
              <img
                alt="solution"
                src="assets/img/solution1.png"
                style={{ width: "90%", }}
              />
            </Grid>
            <Grid item xs={2} ></Grid>
          </Grid>
          <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={"0px"} >
          <Grid item xs={1} ></Grid>
          <Grid item xs={10} >
              <img
                alt="solution"
                src="assets/img/solution2.png"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={1} ></Grid>
            </Grid>
            </Box>
        </Box>
        </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <img
          alt="solution"
          src="assets/img/solutionsdivider.png"
          style={{ width: "100% " }}
        />
      </Grid>
    </Grid>
  );
};

export default Soloution;

import React from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import Main from "../../layout/Main";

export default function ShippingPolicy() {
  return (
    <Main>
      <Container>
        <Box pt={20} sx={{ flexGrow: 1 }}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h3" color="white">
                Shipping Policy
              </Typography>
              <Typography color="white">Last updated: Mar 31, 2024</Typography>
              <br /> <br />
              <Typography color="white" variant="body">
                MINDSCLIK through its platform MPERITO provides ability for its customers to launch their branded ecommerce application on web and mobile. As part of the solution few of its customers (businesses) can leverage MINDSCLIK payment gateway to collect money on the purchases made end customer.
              </Typography>
              <br />
              <Typography color="white" variant="body">
                The following Shipping Policy is applicable to the Customers or Businesses that are leveraging the MPERITO platform along with its payment gateway:

              </Typography>
              <br /> <br />

              <Typography color="white" variant="h4">
                Shipping Process
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Orders will be processed and shipped within 2-3 business days from the date of purchase.
                </li>
              </Typography>
              <br />

              <br />
              <Typography color="white" variant="body">
                <li>
                  Shipping times may vary depending on the destination and shipping method selected by the customer.
                </li>
              </Typography>
              <br />

              <br />
              <Typography color="white" variant="body">
                <li>
                  Customers will receive a tracking number from the businesses via email once the order has been shipped.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Order delivery status can be tracked by customer by login into the application and view the order history / details
                </li>
              </Typography>
              <br />


              <br /> <br />

              <Typography color="white" variant="h4">
                Shipping Rates
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Shipping rates will be calculated based on the weight of the package and the destination address.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Customers will have the option to choose from various shipping methods at checkout, each with its associated cost.
                </li>
              </Typography>
              <br />


              <Typography color="white" variant="h4">
                International Shipping
              </Typography>
              <br />


              <Typography color="white" variant="body">
                <li>
                  We offer international shipping to select countries. International customers are responsible for any customs duties, taxes, or fees incurred during importation
                </li>
              </Typography>
              <br />
              <br />
              <Typography color="white" variant="h4">
                Shipping Address
              </Typography>
              <br />

              <Typography color="white" variant="body">
                <li>
                  Customers are responsible for providing accurate shipping addresses. MINDSCLIK and MPERITO are not liable for any delays or non-delivery due to incorrect address information provided by the customer.
                </li>
              </Typography>
              <br />
              <br />

              <Typography color="white" variant="h4">
                Delivery Issues
              </Typography>
              <br />

              <Typography color="white" variant="body">
                <li>
                  In the event of a delivery issue such as non-delivery or damaged goods, customers are advised to contact our customer support team for assistance

                </li>
              </Typography>
              <br />


              <br />
              <Typography component="h1" variant="h4" color="white">
                Changes to this Shipping Policy
              </Typography>
              <Typography component={"p"} color="white" variant="body">
                We may update Our Shipping Policy from time to time. We will
                notify You of any changes by posting the new Shipping Policy on
                this page.
              </Typography>
              <br />
              <Typography component={"p"} color="white" variant="body">
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Shipping Policy.
              </Typography>
              <br />
              <Typography component={"p"} color="white" variant="body">
                You are advised to review this Shipping Policy periodically for
                any changes. Changes to this Shipping Policy are effective when
                they are posted on this page.
              </Typography>
              <br />
              <Typography component="h1" variant="h4" color="white">
                Contact Us
              </Typography>
              <Typography component={"p"} color="white" variant="body">
                If you have any questions about this Shipping Policy, You can
                contact us:
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  By email:{" "}
                  <a
                    href="/cdn-cgi/l/email-protection"
                    class="__cf_email__"
                    data-cfemail="bdcec8cdcdd2cfc9fdd0d4d3d9ceded1d4d693ded2d0"
                    style={{ color: "#00c3ff" }}
                  >
                    support@mindsclik.com
                  </a>
                </li>
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Main>
  );
}

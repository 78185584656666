import { React } from "react";
import { Grid, Typography, Box, Fab } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const socialMedia = [
  {
    id: 1,
    icon: <WhatsAppIcon />,
    link: "",
  },
  {
    id: 2,
    icon: <FacebookOutlinedIcon />,
    link: "",
  },
  {
    id: 3,
    icon: <InstagramIcon />,
    link: "",
  },
  {
    id: 4,
    icon: <TwitterIcon />,
    link: "",
  },
  {
    id: 5,
    icon: <AlternateEmailIcon />,
    link: "",
  },
];

const ContentContact = () => {
  return (
    // <Grid container spacing={2}>
    //   <Grid item xs={12}>
    //     <Box sx={{ flexGrow: 1 }}>
    //       <Grid container spacing={0}>
    //         <Grid item xs={12}>
    //           <Typography
    //             variant="h1"
    //             color="text.secondary"
    //             textTransform="uppercase"
    //           >
    //             contact us
    //           </Typography>
    //         </Grid>
    //         <Grid item xs={12}>
    // <Typography
    //   variant="subtitle1"
    //   color="text.secondary"
    //   textTransform="uppercase"
    // >
    //   take your business to the next level
    // </Typography>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   </Grid>
    // <Grid item>
    //   <Box sx={{ flexGrow: 1 }}>
    //     <Grid
    //       container
    //       spacing={2}
    //       sx={{
    //         display: "flex",
    //         justifyContent: "flex-start",
    //         alignContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Grid item>
    //         <Typography
    //           variant="subtitle1"
    //           color="text.secondary"
    //           textTransform="uppercase"
    //         >
    //           Lorem ipsum dolor sit amet
    //         </Typography>
    //       </Grid>
    //       <Grid item>
    //         <LocationOnIcon sx={{ color: "secondary.main" }} />
    //       </Grid>
    //     </Grid>
    //   </Box>
    // </Grid>
    //   <Grid item xs={12}>
    //     <Box sx={{ flexGrow: 1 }}>
    //       <Grid
    //         container
    //         spacing={2}
    //         sx={{
    //           display: "flex",
    //           justifyContent: "flex-start",
    //           alignContent: "center",
    //           alignItems: "center",
    //         }}
    //       >
    //         <Grid item>
    //           <Typography
    //             variant="subtitle1"
    //             color="text.secondary"
    //             textTransform="uppercase"
    //           >
    //             Lorem ipsum dolor sit amet
    //           </Typography>
    //         </Grid>
    //         <Grid item>
    //           <EmailIcon sx={{ color: "secondary.main" }} />
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   </Grid>
    //   <Grid item xs={10.5}>
    //     <img
    //       alt="solution"
    //       src="assets/img/map.png"
    //       style={{ width: "100%" }}
    //     />
    //   </Grid>
    //   <Grid item xs={12}>
    //     <Box sx={{ flexFlow: 1 }}>
    //       <Grid
    //         container
    //         direction="row"
    //         justifyContent="space-around"
    //         alignItems="center"
    //       >
    //         {socialMedia.map((item) => (
    //           <Grid item key={item.id}>
    //             <Fab
    //               size="small"
    //               sx={{ bgcolor: "button.light", color: "secondary.light" }}
    //             >
    //               {item.icon}
    //             </Fab>
    //           </Grid>
    //         ))}
    //       </Grid>
    //     </Box>
    //   </Grid>
    // </Grid>
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      alignItems="flex-end"
      alignContent="flex-end"
    >
      <Grid item xs={12}>
        <Typography
          variant="h1"
          color="text.secondary"
          textTransform="uppercase"
        >
          contact us
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          textTransform="uppercase"
        >
          take your business to the next level
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                textTransform="uppercase"
              >
                Bengaluru, Karnataka, India
              </Typography>
            </Grid>
            <Grid item>
              <LocationOnIcon sx={{ color: "secondary.main" }} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                textTransform="lowercase"
              >
                support@mindsclik.com
              </Typography>
            </Grid>
            <Grid item>
              <EmailIcon sx={{ color: "secondary.main" }} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <img
          alt="solution"
          src="/assets/img/map.png"
          style={{ width: "100%" }}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Box sx={{ flexFlow: 1 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            {socialMedia.map((item) => (
              <Grid item key={item.id}>
                <Fab
                  size="small"
                  sx={{ bgcolor: "button.light", color: "secondary.light" }}
                >
                  {item.icon}
                </Fab>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid> */}
    </Grid>
  );
};
export default ContentContact;

import axios from 'axios';


const gateway_port = process.env.REACT_APP_API_PORT || 7700;
const gateway_protocol = process.env.REACT_APP_PROTOCOL || 'http';
const api_hostname = process.env.REACT_APP_API_HOSTNAME || window.location.hostname;

const port = ( gateway_port === "80" ) ? "" : ":" + gateway_port;

const url = gateway_protocol + "://" + api_hostname + port;

// console.log("ENV : "  +  process.env);

// console.log(
//     "AXIOS URL : " + url + "EnvPort : " + process.env.REACT_APP_API_PORT
// );

const instance = axios.create({
    baseURL : url,
    timeout : 3000,
})

export default instance;
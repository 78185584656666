import axios from "../utils/axios";




class authServices {
  authMobile = (values) => {
    new Promise((resolve, reject) => {

      const payload = {
        "appName": values?.businessName,
        "mobile": values?.mobileno,
      }

      axios
        .post("/api/auth/delete/account/send-otp", payload)
        .then((response) => {
          //console.log("budgetServices:budget", response.data);
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            // console.log(
            //   "Response:addbudget:error " + JSON.stringify(response.data)
            // );
            resolve(response.data.payload);
          }
        })
        .catch((error) => {
          // console.log("budgetService:addbudget:error", error);
          reject(error);
        });
    });
  };

  authMobileWithOtp = (values) => {
    new Promise((resolve, reject) => {

      const payload = {
        "appName": values?.businessName,
        "mobile": values?.mobileno,
        "otp": values?.otp,
      }

      axios
        .post("/api/auth/delete/user", payload)
        .then((response) => {
          //console.log("budgetServices:budget", response.data);
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            // console.log(
            //   "Response:addbudget:error " + JSON.stringify(response.data)
            // );
            resolve(response.data.payload);
          }
        })
        .catch((error) => {
          // console.log("budgetService:addbudget:error", error);
          reject(error);
        });
    });
  };

}

const authservices = new authServices();

export default authservices;

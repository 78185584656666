import React from "react";
import { Grid, Box, Container } from "@mui/material";
import Main from "../../layout/Main";
import DigitalBrifcase from "./components/digitalbriefcase/DigitalBrifcase";
import KeyFeatures from "./components/keyFeatures/keyFeatures";
import Digitalcatalog from "./components/digitalCatalog/Digitalcatalog";
import Ecommerceonline from "./components/ecommerceOnline/Ecommerceonline";
import Stayconnected from "./components/stayConnected/Stayconnected";



export default function DigitalBriefcases() {
  return (
    <Main>
     <Container>
     <Box pt={20} sx={{ flexGrow: 1 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
           <DigitalBrifcase/>
          </Grid>
          <Grid item xs={12}>
            <KeyFeatures/>
          </Grid>
          <Grid item xs={12}>
           <Digitalcatalog/>
          </Grid>
          <Grid item xs={12}>
            <Ecommerceonline/>
          </Grid>
          <Grid item xs={12}>
          <Stayconnected/>
          </Grid>
        </Grid>
      </Box>
     </Container>
    </Main>
  );
}

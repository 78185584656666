/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid } from "@mui/material";

const Carousel = ({imageData}) => {

  const [arrows, setArrows] = useState(false);
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    cssEase: "linear",
    autoplay: true,
    fade: true,
    waitForAnimate: false,
    centerMode: true,
    arrows
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {imageData?.map((item) => (
          // eslint-disable-next-line react/jsx-no-comment-textnodes
          <Grid container spacing={2}>
            <Grid item md={4}>
              <img
                src={item.img}
                style={{ objectFit: "scale-down", width: 500, height: 500 }}
              />
            </Grid>
          </Grid>
        ))}
        {/* <div>
              <h3>2</h3>
            </div>
            <div>
              <h3>3</h3>
            </div>
            <div>
              <h3>4</h3>
            </div>
            <div>
              <h3>5</h3>
            </div>
            <div>
              <h3>6</h3>
            </div> */}
      </Slider>
    </div>
  );
};

export default Carousel;

import { React } from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { PropTypes } from "prop-types";

function FeatureSection({ title, subtitle, features, imgSrc, imgLeft }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        marginTop={4}
        marginBottom={10}
        direction={imgLeft ? "row-reverse" : ""}
      >
        <Grid item xs={12} sm={12} md={5.5}>
          <Grid item xs={12} marginBottom={2}>
            <Typography
              variant="featureSubtitle"
              color="text.secondary"
              textTransform="uppercase"
            >
              {subtitle}
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={4}>
            <Typography
              variant="featureTitle"
              color="text.secondary"
              textTransform="capitalize"
            >
              {title}
            </Typography>
          </Grid>

          {features?.map((feature) => (
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={1}>
                  <img
                    alt="solution"
                    src="/assets/icons/handarrow.png"
                    style={{ width: "90%" }}
                  />
                </Grid>
                <Grid item xs={11} spacing={0}>
                  <Typography
                    variant="featureList"
                    color="text.main"
                    // textTransform="capitalize"
                  >
                    {feature}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} sm={12} md={0.5}></Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid item xs={12}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item>
                  <img alt="solution" src={imgSrc} style={{ width: "100%" }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

FeatureSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  imgSrc: PropTypes.string,
  imgLeft: PropTypes.bool,
};

export default FeatureSection;

import React from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  IconButton,
  Container,
  CardMedia,
  Card,
} from "@mui/material";
import TreeDigram from "./components/TreeDigram";
import ContentBenefits from "./components/ContentBenefits";

const BenfitsMperito = () => {
  return (
    <Grid>
      <Grid sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <CardMedia image="/assets/img/benfits_bg.png" sx={{ height: "100vh" }}>
          <Container>
            <Grid>
              <Grid sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TreeDigram />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <ContentBenefits />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </CardMedia>
      </Grid>
      <Grid sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
          <Grid item xs={1} ></Grid>
            <Grid item xs={10} >
              <ContentBenefits />
            </Grid>
            <Grid item xs={1} ></Grid>
            <Grid item xs={1} ></Grid>
            <Grid item xs={10}>
              <TreeDigram />
            </Grid>
            <Grid item xs={1} ></Grid>
            <img
                alt="solution"
                src="/assets/img/benfits_bg.png"
                style={{ width: "100%"}}
              />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BenfitsMperito;

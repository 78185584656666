import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import { light, dark } from "./palette";
import { typography as overrideTypography } from "./typography";

const getTheme = (mode, themeToggler) =>
  responsiveFontSizes(
    createTheme({
      palette: mode === "light" ? light : dark,
      // palette:light,
      shadows: shadows(mode),
      typography: overrideTypography,
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        // MuiTypography: {
        //   display: 'block',
        //  },
        MuiButton: {
          styleOverrides: {
            root: {
              font: "Josefin Sans",
              fontWeight: 400,
              borderRadius: 5,
              paddingTop: 10,
              paddingBottom: 10,
              // backgroundColor: '#00c3ff',
              "&:hover": {
                backgroundColor: "#00c3ff",
                opacity: [0.9, 0.8, 0.7],
              },
            },
            containedSecondary: {},
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
            input: {
              borderRadius: 5,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 16,
              boxShadow: 4,
              bgcolor: "#101010",
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            "*::-webkit-scrollbar": {
              width: "5px",
            },
            "*::-webkit-scrollbar-track": {
              background: "#E4EFEF",
            },
            "*::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "3px",
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              "& label.Mui-focused": {
                color: "#ffffff",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "#00c3ff",
                "&:hover": {
                  borderBottomColor: "#00c3ff", 
                },
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#00c3ff",
                "&:hover": {
                  borderBottomColor: "#00c3ff",
                },
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#00c3ff",
                },
                "&:hover fieldset": {
                  borderColor: "#00c3ff",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#00c3ff",
                },
                "&..MuiInputBase-input": {
                  color: "#ffffff",
                },
              },
            },
          },
        },
      },
      themeToggler,
    })
  );

export default getTheme;

import React from "react";
import {
  Grid,
  Box,
  Toolbar,
  Divider,
  Button,
  ListItem,
  CardMedia,
  List,
  
} from "@mui/material";
import Sidebar from "../../../sidebar/sidebar";

import { Link } from "react-router-dom";

import Buttonlist from "../../../../../../components/Buttonlist";
import RegisterFormDialog from "../RegisterFormDialog";

const navItems = [
  {
    id: 1,
    name: "Home",
    link: "/home",
  },
  // {
  //   id:2,
  //   name:'About us',
  //   link:'',
  // },
  {
    id: 3,
    name: <Buttonlist />,
    link: "",
  },
  // {
  //   id:4,
  //   name:'Pricing',
  //   link:'',
  // },
  // {
  //   id:5,
  //   name:'Blogs',
  //   link:'',
  // },
  // {
  //   id:6,
  //   name:'Demo',
  //   link:'',
  // },
];

export default function TopToolbar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <Toolbar>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid>
          <CardMedia
            image="/assets/img/mperito_logo.png"
            component={Link}
            to={'/home'}
            alt="logo"
            sx={{
              height: { xs: 35, sm: 35, md: 51 },
              width: { xs: 157, sm: 157, md: 225 },
            }}
          />
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          color="#ffffff"
          sx={{ display: { xs: "none", sm: "block" } }}
        />
        <Grid sx={{ display: { xs: "none", sm: "block" } }}>
          {navItems.map((item) => (
            <Button
              key={item.id}
              sx={{
                backgroundColor: "transparent",
                color: "text.main",
                "&:hover": {
                  backgroundColor: "secondary.main",
                },
              }}
              href={item.link}
            >
              {item.name}
            </Button>
          ))}
        </Grid>

        <Sidebar>
          <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Grid p={4}>
              <CardMedia
                image="/assets/img/mperito_logo.png"
                alt="logo"
                sx={{
                  height: { xs: 35, sm: 35, md: 51 },
                  width: { xs: 157, sm: 157, md: 225 },
                }}
              />
            </Grid>
            <List>
              {navItems?.map((navitem) => (
                <ListItem>
                  <Button
                    key={navitem.id}
                    sx={{
                      backgroundColor: "transparent",
                      color: "text.main",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    href={navitem.link}
                  >
                    {navitem.name}
                  </Button>
                </ListItem>
              ))}
            </List>
            <Divider color="#ffffff" />
            <Grid
              pt={2}
              sx={{
                display: {
                  xs: "flex",
                },
                justifyContent: "center",
              }}
            >
              {/* <Button
                sx={{
                  bgcolor: "secondary.main",
                  color: "text.main",
                  width: "120px",
                  borderRadius: "60px",
                }}
              >
                Register
              </Button> */}
              <RegisterFormDialog/>
            </Grid>
          </Box>
        </Sidebar>
        <Grid
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            justifyContent: "flex-end",
          }}
        >
          {/* <Button
            sx={{
              bgcolor: "secondary.main",
              color: "text.main",
              width: "120px",
              borderRadius: "60px",
            }}
          >
            Register
          </Button> */}
            <RegisterFormDialog/>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

import { React } from "react";
import { Grid, Box } from "@mui/material";
import Briefcase from "../../../../components/Briefcase";

const briefcase = [
  {
    title: "DIGITAL CATALOG",
    subtitle: "CONTENT RICH CATALOGING",
    imageUrl: "/assets/img/digitalCatlog.png",
    features: [
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ibh euismod tincidunt ut laoreet dolore",

      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ibh euismod tincidunt ut laoreet dolore",

      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy ibh euismod tincidunt ut laoreet dolore",
    ],
  },
];

const Digitalcatalog = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      {briefcase.map((item) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Briefcase
              key={item.title}
              title={item.title}
              subtitle={item.subtitle}
              features={item.features}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <img alt="solution" src={item.imageUrl} style={{ width: "100%" }} />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
export default Digitalcatalog;

import React, { useState, useRef, useEffect } from "react";

import {
  Button,
  Grid,
  MenuList,
  MenuItem,
  Typography,
  Menu,
  Divider,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Link } from "react-router-dom";

const options = [
  {
    id: 1,
    name: "Shop",
    link: "/digitalshop",
  },  
  {
    id: 2,
    name: "Digital Briefcase",
    link: "/solutions/digitalbriefcase",
  },
];
const Buttonlist = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid>
      <Button
        size="small"
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          height:'0px',
          color: "text.main",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Typography variant="button1"> solution</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
          //  border:'solid',
          //  borderColor:'#00c3ff',
          backgroundColor:"#00c3ff"
          },
        }}
      >
          <MenuList>
            {options.map((item) => (
              <MenuItem
                {...item.id}
                onClick={handleClose}
                component={Link}
                to={item.link}
                sx={{
                  backgroundColor: "secondary.main",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  color: "secondary.light",

                }}
                // sx={{color: "secondary.light"}}
              >
                {item.name}
                <Divider/>
              </MenuItem>
            ))}
          </MenuList>
      </Menu>
    </Grid>
  );
};

export default Buttonlist;

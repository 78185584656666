import React from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import Main from "../../layout/Main";

export default function RefundPolicy() {
  return (
    <Main>
      <Container>
        <Box pt={20} sx={{ flexGrow: 1 }}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h3" color="white">
                Refund Policy
              </Typography>
              <Typography color="white">Last updated: Mar 31, 2024</Typography>
              <br /> <br />
              <Typography color="white" variant="body">
                MINDSCLIK through its platform MPERITO provides ability for its customers to launch their branded ecommerce application on web and mobile. As part of the solution few of its customers (businesses) can leverage MINDSCLIK payment gateway to collect money on the purchases made end customer.
              </Typography>
              <br />
              <Typography color="white" variant="body">
                The following Return Policy is applicable to the Customers or Businesses that are leveraging the MPERITO platform along with its payment gateway:

              </Typography>
              <br /> <br />

              <Typography color="white" variant="h4">
                Refund Eligibility
              </Typography>
              <br />
              <Typography color="white" variant="body">

                Refunds may be issued in the following circumstances:

              </Typography>
              <Typography color="white" variant="body">
                <li>
                  The customer cancels an order before it has been shipped.
                </li>
              </Typography>
              <br />

              <br />
              <Typography color="white" variant="body">
                <li>
                  The customer returns a product in accordance with our return policy.
                </li>
              </Typography>
              <br />

              <br />
              <Typography color="white" variant="body">
                <li>
                  A refund is required due to a mistake on our part (e.g., billing errors, defective products).
                </li>
              </Typography>
              <br />

              <br /> <br />

              <Typography color="white" variant="h4">
                Refund Process
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Customers must contact our customer support team to request a refund.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Refunds will be processed back to the original payment method within 15 business days of the refund request being approved.
                </li>
              </Typography>
              <br />


              <Typography color="white" variant="h4">
                Refund Exceptions
              </Typography>
              <br />


              <Typography color="white" variant="body">
                <li>
                  We reserve the right to refuse refunds in cases where products are returned in a used or damaged condition, or if the return does not meet our return policy criteria.
                </li>
              </Typography>
              <br />


              <br />
              <Typography component="h1" variant="h4" color="white">
                Changes to this Refund Policy
              </Typography>
              <Typography component={"p"} color="white" variant="body">
                We may update Our Refund Policy from time to time. We will
                notify You of any changes by posting the new Refund Policy on
                this page.
              </Typography>
              <br />
              <Typography component={"p"} color="white" variant="body">
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Refund Policy.
              </Typography>
              <br />
              <Typography component={"p"} color="white" variant="body">
                You are advised to review this Refund Policy periodically for
                any changes. Changes to this Refund Policy are effective when
                they are posted on this page.
              </Typography>
              <br />
              <Typography component="h1" variant="h4" color="white">
                Contact Us
              </Typography>
              <Typography component={"p"} color="white" variant="body">
                If you have any questions about this Refund Policy, You can
                contact us:
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  By email:{" "}
                  <a
                    href="/cdn-cgi/l/email-protection"
                    class="__cf_email__"
                    data-cfemail="bdcec8cdcdd2cfc9fdd0d4d3d9ceded1d4d693ded2d0"
                    style={{ color: "#00c3ff" }}
                  >
                    support@mindsclik.com
                  </a>
                </li>
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Main>
  );
}

import { React } from "react";
import { Grid, Typography, Box } from "@mui/material";

const ContentApp = () => {
  return (
    <Grid>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} textAlign={"center"}  >
          <Grid item xs={12}>
            <Typography
              variant="h1"
              color="text.secondary"
              textTransform="uppercase"
            >
              one stop solution app
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="text.main"
              textTransform="uppercase"
            >
              provides ability for your customers to purchase online and
              allows you to manage and operate your business on the same
              platform
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
export default ContentApp;

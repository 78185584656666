import React from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import ContactForm from "./components/ContactForm";
import ContentContact from "./components/ContentContact";

const Contact = () => {
  return (
    <Grid>
      <Grid display={{ xs: "block", sm: "block", md: "none" }}>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <ContentContact />
              </Grid>
              <Grid item pt={3} xs={6} position={"absolute"} zIndex={3}>
                <img
                  alt="contact"
                  src="/assets/img/contact.png"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ContactForm />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid>
      <Grid display={{ xs: "none", sm: "none", md: "block" }}>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={'0px'}>
              <Grid item xs={4}>
                <ContactForm />
              </Grid>
              <Grid item xs={4}>
                <img
                  alt="contact"
                  src="/assets/img/contact.png"
                  style={{ width: "50vh" }}
                />
              </Grid>
              <Grid item xs={4}>
                <Box pt={4}>
                <ContentContact />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Contact;

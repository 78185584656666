import React from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import Main from "../../layout/Main";

export default function TermsAndConditions() {
  return (
    <Main>
      <Container>
        <Box pt={20} sx={{ flexGrow: 1 }}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h3" color="white">
                Terms and Conditions
              </Typography>
              <Typography color="white">Last updated: Mar 31, 2024</Typography>
              <br /> <br />
              <Typography color="white" variant="body">
                MINDSCLIK through its platform MPERITO provides ability for its customers to launch their branded ecommerce application on web and mobile. As part of the solution few of its customers (businesses) can leverage MINDSCLIK payment gateway to collect money on the purchases made end customer.
              </Typography>
              <br />
              <Typography color="white" variant="body">
                The following Terms and Conditions is applicable to the Customers or Businesses that are leveraging the MPERITO platform along with its payment gateway:

              </Typography>
              <br /> <br />

              <Typography color="white" variant="h4">
                Accountability for Brands Leveraging MINDSCLIK Payment Account
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Brands utilizing the MINDSCLIK payment account for transactions are solely responsible for the accuracy of their product listings, order fulfilment, and customer service.
                </li>
              </Typography>
              <br />

              <br />
              <Typography color="white" variant="body">
                <li>
                  MINDSCLIK and MPERITO are not liable for any disputes, financial losses, or damages arising from transactions conducted by brands through the MINDSCLIK payment account.
                </li>
              </Typography>
              <br />

              <br /> <br />

              <Typography color="white" variant="h4">
                Intellectual Property Rights
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Brands retain ownership of their intellectual property rights related to product listings, branding, and content uploaded to the MPERITO platform.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  By using the platform, brands grant MINDSCLIK and MPERITO a non-exclusive license to use, reproduce, and display their intellectual property for the purpose of operating and promoting the platform.
                </li>
              </Typography>
              <br />


              <Typography color="white" variant="h4">
                Data Privacy and Security
              </Typography>
              <br />


              <Typography color="white" variant="body">
                <li>
                  MINDSCLIK and MPERITO are committed to protecting the privacy and security of user data in accordance with applicable laws and regulations.

                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  Brands agree not to misuse or disclose any confidential information obtained through the platform, including customer data and proprietary information.
                </li>
              </Typography>
              <br />
              <br />

              <Typography color="white" variant="h4">
                Indemnification
              </Typography>
              <br />

              <Typography color="white" variant="body">
                <li>
                  Brands agree to indemnify and hold MINDSCLIK and MPERITO harmless from any claims, losses, liabilities, damages, or expenses (including legal fees) arising from their use of the platform or any violation of these terms and conditions.
                </li>
              </Typography>
              <br />
              <br />

              <Typography color="white" variant="h4">
                Governing Law and Dispute Resolution:
              </Typography>
              <br />

              <Typography color="white" variant="body">
                1. Governing Law
              </Typography>
              <br />

              <Typography color="white" variant="body">
                <li>
                  These terms and conditions shall be governed by and construed in accordance with the laws of the Republic of India.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                2. Arbitration
              </Typography>
              <br />

              <Typography color="white" variant="body">
                <li>
                  Any disputes, controversies, or claims arising out of or relating to these terms and conditions, including any disputes regarding its existence, validity, or termination, shall be referred to and finally resolved by arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  The arbitration proceedings shall be conducted by a sole arbitrator appointed by mutual agreement between the parties. If the parties fail to agree on the appointment of an arbitrator within 30 days of a written demand for arbitration, the arbitrator shall be appointed by the [Appointing Authority], in accordance with the Arbitration and Conciliation Act, 1996.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  The arbitration proceedings shall be conducted in the English language and shall be held in Benguluru, India.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  The decision of the arbitrator shall be final and binding on the parties, and judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.
                </li>
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  The costs of arbitration, including the fees and expenses of the arbitrator, shall be borne equally by the parties, unless the arbitrator directs otherwise in the award.
                </li>
              </Typography>
              <br />


              <Typography color="white" variant="body">
                3. Applicable Law
              </Typography>
              <br />

              <Typography color="white" variant="body">
                <li>
                  The arbitration proceedings and the enforcement of any arbitral award shall be governed by the laws of the Republic of India.
                </li>
              </Typography>
              <br />


              <Typography color="white" variant="body">
                4. Jurisdiction
              </Typography>
              <br />

              <Typography color="white" variant="body">
                <li>
                  The courts in Benguluru, India, shall have exclusive jurisdiction over any proceedings arising out of or in connection with the arbitration or enforcement of any arbitral award.
                </li>
              </Typography>
              <br />

              <br />
              <Typography component="h1" variant="h3" color="white">
                Changes to this Terms and Conditions
              </Typography>
              <Typography component={"p"} color="white" variant="body">
                We may update OurTerms and Conditions from time to time. We will
                notify You of any changes by posting the new Terms and Conditions on
                this page.
              </Typography>
              <br />
              <Typography component={"p"} color="white" variant="body">
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Terms and Conditions.
              </Typography>
              <br />
              <Typography component={"p"} color="white" variant="body">
                You are advised to review this Terms and Conditions periodically for
                any changes. Changes to this Terms and Conditions are effective when
                they are posted on this page.
              </Typography>
              <br />
              <Typography component="h1" variant="h3" color="white">
                Contact Us
              </Typography>
              <Typography component={"p"} color="white" variant="body">
                If you have any questions about this Terms and Conditions, You can
                contact us:
              </Typography>
              <br />
              <Typography color="white" variant="body">
                <li>
                  By email:{" "}
                  <a
                    href="/cdn-cgi/l/email-protection"
                    class="__cf_email__"
                    data-cfemail="bdcec8cdcdd2cfc9fdd0d4d3d9ceded1d4d693ded2d0"
                    style={{ color: "#00c3ff" }}
                  >
                    support@mindsclik.com
                  </a>
                </li>
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Main>
  );
}

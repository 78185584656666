import { React } from "react";
import { CardMedia, Grid, Typography, Box, Card } from "@mui/material";
import SolutionCardDesk from "./SolutionCardDesk";
import SolutionCardDeskMobile from "./SolutionCardDeskMobile ";

const data = [
  {
    id: 1,
    title: "Mperito Digital Briefcase",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
  },
  {
    id: 2,
    title: "Mperito Digital Briefcase",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
  },
  {
    id: 3,
    title: "Mperito Digital Briefcase",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
  },
];

const AppDigram = () => {
  return (
    <Grid>
      <Grid sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Grid
          pt={10}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <SolutionCardDesk />
        </Grid>
      </Grid>
      <Grid sx={{ display: { xs: "content", sm: "content", md: "none" } }}>
        <Grid
          pt={10}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
         <SolutionCardDeskMobile/>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AppDigram;

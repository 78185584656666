import { React } from "react";
import { Box, Grid, Typography } from "@mui/material";

const DigitalBrifcase = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  DIGITAL JOURNEY MADE EASY
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="text.main"
                  textTransform="capitalize"
                >
                  Mobile based platform solution empowering OEMs, MSMEs & SMEs
                  to digitize product catalogs, Distribution & Service Channels,
                  Sales and Purchase, order tracking and communication on single
                  platform. Lorem ipsum dolor sit amet, consectetuer adipiscing
                  elit, sed diam nonummy ibh euismod tincidunt ut laoreet dolore
                  magna aliquam. Lorem ipsum dolor sit amet, consectetuer
                  adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                  laoreet dolore magna aliquam
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <img
            alt="solution"
            src="assets/img/digitalbrifcase.png"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default DigitalBrifcase;

// <Box sx={{ flexGrow: 1 }}>
//     <Grid container spacing={2}>
//       <Grid item xs={6}>
//         <Box sx={{ flexGrow: 1 }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12}></Grid>
//             <Grid item xs={12}></Grid>
//           </Grid>
//         </Box>
//       </Grid>
//       <Grid item xs={6}>
//         <img
//           alt="solution"
//           src="assets/img/benfittreedig.png"
//           style={{ width: "100%" }}
//         />
//       </Grid>
//     </Grid>
//   </Box>

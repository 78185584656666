import { React } from "react";
import { Grid ,Typography,Box} from "@mui/material";

const ContentBenefits = () => {
  return (
    <Grid>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} textAlign={"end"}>
          <Grid item xs={12} >
            <Typography
              variant="h1"
              color="text.secondary"
              textTransform="uppercase"
             
            >
              who can benefit
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              textTransform="uppercase"
            >
              vendors with distribution as network
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
export default ContentBenefits;

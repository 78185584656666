import { React } from "react";
import { CardMedia, Grid, Typography, Box, Card } from "@mui/material";
import SolutionContentCard from "src/components/SolutionContentCard";

const data = [
  {
    id: 1,
    title: "Digital Briefcase",
    subtitle:
      "Mobile based Platform Solution empowering OEMs, MSMEs and SMEs to digitize Product Catalogs, Distribution & Service Channels, Sales and Purchase, order tracking and communication on single platform",
    link: "/solutions/digitalbriefcase",
  },
  {
    id: 2,
    title: "Digital Shop",
    subtitle:
      "Mobile based Platform Solution empowering Vendors to launch their brand specific app allowing them to operate the complete sales and order tracking for the orders placed by customers",
    link: "/digitalshop",
  },
  {
    id: 3,
    title: "Market Place",
    subtitle:
      "Mobile based Platform Solution that will enable sellers to sell products on market place / ONDC and buyers to make purchase from the same platform ( work in progress )",
    link: "",
  },
];

const SolutionCardDesk = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={"0px"}
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid
          item
          xs={1.5}
          sm={1.5}
          md={1.5}
          lg={2}
          xl={2}
          display={"flex"}
          alignItems={"flex-end"}
        >
          <img
            alt="solution"
            src="/assets/img/card_left.png"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={8} sm={8} md={9} lg={8} xl={8}>
          <Box
            sx={{
              backgroundImage: `url("/assets/img/card_center.png")`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "100%",
            }}
          >
            <Grid
              container
              // direction="row"
              // justifyContent="center"
              // alignItems="center"
              // alignContent="center"
              columnSpacing={{ xs: 6, sm: 6, md: 5, lg: 6, xl: 6 }}
            >
              {data.map((item) => (
                <Grid item xs={4} p={1} key={item.id}>
                  <SolutionContentCard
                    cardTitle={item.title}
                    cardSubtitle={item.subtitle}
                    link={item.link}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={1.5}
          sm={1.5}
          md={1.5}
          lg={2}
          xl={2}
          display={"flex"}
          alignItems={"flex-end"}
        >
          <img
            alt="solution"
            src="/assets/img/card_right.png"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default SolutionCardDesk;

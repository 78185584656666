import React from "react";

import {
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { PlayArrow } from "@mui/icons-material";

import { Link } from "react-router-dom";


const SolutionContentCard = ({ cardTitle, cardSubtitle,link }) => {
  return (
    // <Grid item xs={3.5}>
    <Card
      sx={{
        backgroundColor: "transparent",
        width: "100%",
        boxShadow: "none",
      }}
    >
      <CardContent>
        <Grid mt={1}  textAlign= "center">
          <Typography variant="cardTitle">{cardTitle}</Typography>
        </Grid>
        <Grid mt={2} textAlign= "justify" height={"120px"}>
          <Typography variant="cardSubtitle">{cardSubtitle}</Typography>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="View More">
            <IconButton
              aria-label="play/pause"
              sx={{
                bgcolor: "secondary.main",
                "&:hover": {
                  backgroundColor: "secondary.main",
                },
              }}
              href={link}
            >
              <PlayArrow
                sx={{ height: 12, width: 12, color: "secondary.light" }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </CardContent>
    </Card>
    // </Grid>
  );
};

export default SolutionContentCard;

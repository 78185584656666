import { React } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { PropTypes } from "prop-types";

const HeroSection = ({ title, description, imgSrc }) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h1"
                                    color="text.secondary"
                                    textTransform="uppercase"
                                >
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="solutionContent"
                                    color="text.main"
                                >
                                    {description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <img
                        alt="solution"
                        src={imgSrc}
                        style={{ width: "100%" }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

HeroSection.prototype = {
    title: PropTypes.string,
    description: PropTypes.string,
    imgSrc: PropTypes.string,
};

export default HeroSection;

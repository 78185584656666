import React from "react";
import { Grid, Box } from "@mui/material";
import Main from "../../layout/Main";
import HeroContent from "./components/HeroContent";
import Solution from "./components/Solution/solution";
import Benefits from "./components/Benefits/benefitsMperito";
import AppSolution from "./components/AppSolution/Appsolution";
// import SubscribePlan from "./components/Subscribe/SubscribePlan";
import Contact from "./components/ContactUs/Contact";
import { Helmet } from "react-helmet";
import ReveChatComponent from "src/components/ReveChatComponent";


export default function Home() {
  return (
    <Main>
      <Helmet>
        <title>Mperito</title>
      </Helmet>
      <Box pt={20} sx={{ flexGrow: 1 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <HeroContent />
          </Grid>
          <Grid item xs={12}>
            <AppSolution/>
          </Grid>
          <Grid item xs={12}>
            <Solution />
          </Grid>
          <Grid item xs={12}>
            <Benefits />
          </Grid>
          
          {/* <Grid item xs={12}>
            <SubscribePlan/>
          </Grid> */}
          <Grid item xs={12}>
            <Contact/>
          </Grid>
        </Grid>
        <ReveChatComponent />
      </Box>
    </Main>
  );
}

import React, { useEffect } from "react";

const REVE_CHAT_ACCOUNT_ID = process.env.REACT_APP_REVE_CHAT_ACCOUNT_ID;
const REVE_CHAT_VERSION = process.env.REACT_APP_REVE_CHAT_VERSION;

const ReveChatComponent = () => {

  // console.log("ReveChatComponent(): ", REVE_CHAT_ACCOUNT_ID, REVE_CHAT_VERSION);
  // console.log("ReveChatComponent(): env", process.env);
  useEffect(() => {
    window.$_REVECHAT_API ||
      (function (d, w) {
        var r = (window.$_REVECHAT_API = function (c) {
          r._.push(c);
        });
        w.__revechat_account = REVE_CHAT_ACCOUNT_ID; //"3987814";
        w.__revechat_version = REVE_CHAT_VERSION; // 2;
        r._ = [];
        var rc = d.createElement("script");
        rc.type = "text/javascript";
        rc.async = true;
        rc.setAttribute("charset", "utf-8");
        rc.src =
          ("https:" === document.location.protocol ? "https://" : "http://") +
          "static.revechat.com" +
          "/widget/scripts/new-livechat.js?" +
          new Date().getTime();
        var s = d.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(rc, s);
      })(document, window);
  }, []);

  const chatStyle = {
    backgroundColor: '#ff00bf', // Change this to your desired background color
  };

  return <div id="revechat-widget" style={chatStyle} />;

  // return <div>Reve Chat Widget Loading...</div>;
};

export default ReveChatComponent;
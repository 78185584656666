import React from "react";
import { Grid, Box, Container } from "@mui/material";
import Main from "src/layout/Main";
import FeatureSection from "../components/FeatureSection";
import HeroSection from "../components/HeroSection";
import { Helmet } from "react-helmet";

import { DBSectionData, HeroSectionData as heroData } from "./data";
import ReveChatComponent from "src/components/ReveChatComponent";

export function DigitalBrifcase() {

    return (
        <Main>
            <Helmet>
                <title>Mperito Digital Briefcase</title>
            </Helmet>
            <Container>
                <Box pt={20} sx={{ flexGrow: 1 }}>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <HeroSection
                                title={heroData.title}
                                description={heroData.description}
                                imgSrc={heroData.imgSrc} />
                        </Grid>
                        <Grid item xs={12}>
                            {DBSectionData.map(section => (
                                <FeatureSection
                                    title={section.title}
                                    subtitle={section.subtitle}
                                    features={section.features}
                                    imgSrc={section.imgSrc}
                                    imgLeft={section.imgLeft} />
                            ))}
                        </Grid>
                    </Grid>
                </Box>
                <Box >
                    <ReveChatComponent/>
                </Box>
            </Container>
        </Main>
    )
}

export default DigitalBrifcase;
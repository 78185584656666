import React from "react";

import {
  Home as Home,
  DigitalBriefcases as DigitalBriefcases,
  DigitalShop as DigitalShop,
  DigitalBriefcase as DigitalBriefcase,
  PrivacyPolicy as PrivacyPolicyView,
  UserPage as UserPageView,
  Support as SupportPage,
  ShippingPolicy as ShippingPolicyView,
  ReturnPolicy as ReturnPolicyView,
  RefundPolicy as RefundPolicyView,
  TermsAndConditions as TermsAndConditionsView,
} from "./index.js";


const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/solutions/digitalbriefcase",
    element: <DigitalBriefcase />,
  },
  {
    path: "/digitalbriefcases",
    element: <DigitalBriefcases />,
  },
  {
    path: "/digitalshop",
    element: <DigitalShop />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyView />,
  },
  {
    path: "/shipping-policy",
    element: <ShippingPolicyView />,
  },
  {
    path: "/return-policy",
    element: <ReturnPolicyView />,
  },
  {
    path: "/refund-policy",
    element: <RefundPolicyView />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditionsView />,
  },
  {
    path: "/support",
    element: <SupportPage />,
  },
  {
    path: "/delete-my-account/:appname",
    element: <UserPageView/>,
  }
];

export default routes;

import React from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  IconButton,
  Container,
  CardMedia,
  Card,
} from "@mui/material";
import TreeDigram from "./components/AppDigram";
import ContentApp from "./components/ContentApp";
import AppDigram from "./components/AppDigram";
import SolutionCard from "./components/SolutionCardDesk";

const AppSolution = () => {
  return (
    <Grid>
      <Container>
        <Box sx={{ flexGrow: 1, mt: -10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentApp />
            </Grid>
            <Grid item xs={12}>
              <AppDigram />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Grid>
  );
};

export default AppSolution;

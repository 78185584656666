import { React } from "react";
import {
  Typography,
  Grid, 
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';

import contactservices from "src/services/contactServices";

const ContactForm = () => {

  const formik = useFormik({
    initialValues: {
      // FirstName: '',
      // lastName: '',
      name: "",
      mobileno: "",
      email: "",
      // designation:'',
      message: "",
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, "Must be 15 characters or less")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      message: Yup.string()
        .max(200, "Must be 200 characters or less")
        .min(2, "Must be 20 characters or less"),
    }),
    onSubmit: async(values) => {      
      try {
        await (contactservices.contactUs(values));
      } catch (error) {
        //console.log("Form: error", error);
      }
    },
  });
  return (
    <Grid pt={6}> 
      <Typography variant="h4" color={"text.secondary"}>
        let’s talk
      </Typography>
      <Typography variant="subtitle1" color={"text.main"}>
        It will be great to hear from your on ideas, inputs or feedback!!!
      </Typography>
      <Grid
        sx={{
          pt: "15px",
          // color:'#ffffff'
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="name"
                label="FullName"
                name="name"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlinedIcon sx={{color:"secondary.light"}}/>
                    </InputAdornment>
                    
                  ),
                    style:{
                      borderRadius:"60px"
                    }
                }}
              />
              {formik.errors.name && formik.touched.name ? (
                <span style={{ color: "red" }}>{formik.errors.name}</span>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="email"
                label="Email"
                name="email"
                variant="outlined"
                fullWidth
                type={"email"}
                value={formik.values.email}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon sx={{color:"secondary.light"}}/>
                    </InputAdornment>
                    
                  ),
                }}
              />
              {formik.touched.email && formik.errors.email ? (
                <span style={{ color: "red" }}>{formik.errors.email}</span>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
                id="message"
                label="Message"
                name="message"
                variant="outlined"
                fullWidth
                multiline
                maxRows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ChatBubbleOutlineRoundedIcon sx={{color:"secondary.light"}}/>
                    </InputAdornment>
                    
                  ),
                  style:{
                    height:'81px'
                  }
                }}
              />
             
              {formik.touched.message && formik.errors.message ? (
                <span style={{ color: "red" }}>{formik.errors.message}</span>
              ) : null}
            </Grid>
          </Grid>
          <Grid sx={{ pt: "16px" }}>
            <Button
              variant="outlined"
              type="submit"
              sx={{
                borderColor:"secondary.main",
                color: "secondary.light",
                borderRadius: "23px",
                width: "160px",
                height: "46px",
              }}
            >
              send message
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
export default ContactForm;

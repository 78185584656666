import { React } from "react";
import { Grid } from "@mui/material";

const TreeDigram = () => {
  return (
    <Grid>
      <Grid pt={10} sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
        <img
          alt="solution"
          src="/assets/img/benfittreedig_m.png"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid pt={10} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <img
          alt="solution"
          src="/assets/img/benfittreedig.png"
          style={{ width: "100%" }}
        />
      </Grid>
    </Grid>

  );
};
export default TreeDigram;




const HeroSectionData = {
    title: "Digital Briefcase",
    description: "Mobile based Platform Solution empowering OEMs, MSMEs and SMEs to digitize Product Catalogs, Distribution & Service Channels, Sales and Purchase, order tracking and communication on single platform",
    imgSrc: "/assets/img/digital_briefcase.png"    
}

const DBSectionData = [
    {
        title: "Unified Platform",
        subtitle: "Collaboration",
        features: [
            "Bring your entire (OEM) ecosystem on one unified platform",
            "Quickly find distributors and service providers",
            "Stay connected with all stakeholders through news, blogs and greetings"
        ],
        imgSrc: "/assets/img/unified_platform.png",
        imgLeft: true
    },
    {
        title: "Content Rich Cataloging",
        subtitle: "Digital Catalog",
        features: [
            "Access full product catalog on mobile, reducing printing and promoting environmental sustainability",
            "Synchronized price update across platform",
            "Effortlessly remove outdate products and add new products with a single click",
            "Simplify your search for products"
        ],
        imgSrc: "/assets/img/digital_catalog.png",
        imgLeft: false
    },
    {
        title: "Online Shop",
        subtitle: "Ecommerce",
        features: [
            "Place orders to the nearest distributor",
            "Enable online or offline payment",
            "Track Orders and generate invoices effortlessly",
            "Visibility to OEM on product movement; assist in prioritizing the relevant products"
        ],
        imgSrc: "/assets/img/digital_ecommerce.png",
        imgLeft: true
    },
    {
        title: "Enable Sales & Marketing Team",
        subtitle: "Stay Connected",
        features: [
            "Enriched analytics for strategy building",
            "Sales team daily targets and updates within the platform",
            "Sales team promptly adding information to CRM",
            "Trackable updates for future references",
        ],
        imgSrc: "/assets/img/stay_connected.png",
        imgLeft: false
    },

]


export { DBSectionData, HeroSectionData };
import React from "react";
import { Grid, Typography, Box, Button, IconButton, Tooltip } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";

const SolidSolution = () => {
  return (
    <Grid container spacing={0} pt={{ xs: 2, sm: 2, md: 10 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Tooltip title="welcome to mperito">
              <IconButton
                aria-label="play/pause"
                sx={{
                  bgcolor: "secondary.main",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <PlayArrow
                  sx={{ height: 25, width: 25, color: "secondary.light" }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              color="text.main"
            // textTransform="uppercase"
            >
              Get Solid Solutions for Sales & Marketing
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color="text.main"
            // textTransform="uppercase"
            >
              In today's Digital Era it is important that all stakeholders are enjoying the enhanced experience and connectivity into the ecosystem.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default SolidSolution;

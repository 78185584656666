import axios from "../utils/axios";




class contactServices {
  contactUs = (values) => {
    new Promise((resolve, reject) => {

      const payload = {
        "fullName": values?.name,
        "email": values?.email,
        "mobile": values?.mobileno,
        "feedback": values?.message
      }

      axios
        .post("/api/inquiry/contactus", payload)
        .then((response) => {
          //console.log("budgetServices:budget", response.data);
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            // console.log(
            //   "Response:addbudget:error " + JSON.stringify(response.data)
            // );
            resolve(response.data.payload);
          }
        })
        .catch((error) => {
          // console.log("budgetService:addbudget:error", error);
          reject(error);
        });
    });
  };

  register = (values) => {
    new Promise((resolve, reject) => {
      const payload = {
        "fullName": values?.businessname,
        "email": values?.email,
        "mobile": values?.mobileno,
        "whatsAppNumber": values?.whatsappmobileno,
        "helplinemobileno": values?.helplinemobileno,
        "GSTN": values?.gstnno,
        "registeredAddress": values?.address,
        "feedback": "REGISTRATION for business " + values?.businessname
      }

      axios
        .post("/api/inquiry/contactus", payload)
        .then((response) => {
          // console.log("budgetServices:budget", response.data);
          if (response?.data?.error) {
            reject(response?.data?.error);
          } else {
            // console.log(
            //   "Response:addbudget:error " + JSON.stringify(response.data)
            // );
            resolve(response.data.payload);
          }
        })
        .catch((error) => {
          // console.log("budgetService:addbudget:error", error);
          reject(error);
        });
    });
  };

}

const contactservices = new contactServices();

export default contactservices;

export const light = {
  mode: 'light',
  primary: {
    main: '#1459ba',
    dark:'#001BAE'
  },
  secondary: {
    main: '#00c3ff',
    light:'#ffffff',
  },
  text: {
    main: '#ffffff',
    secondary:'#00c3ff',
    ternary :'#0F0F0F'

  },
  button: {
    main: '#00c3ff', 
    light:'#65a3fa'
  },
  background: {
    paper: '#1459ba',
    default: '#1459ba',
  },

};

export const dark = {
  mode: 'dark',
  primary: {
    main: '#3F51B5',   
  },
  secondary: {   
    main: '#834bff', 
  },
  text: {
    primary: '#ffffff',

  },
  button: {
    primary: '#834bff', 
    secondary: '#ffffff', 
  },
  background: {
    paper: '#ffffff',
    default: '#000000',
  },
};

import React from "react";
import { Box, Grid, Container, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';

import Main from "src/layout/Main";

const Support = () => {
  return (
    <Main>
      <Container>
        <Box pt={20} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h3" color="white">
                Support
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item>
                    <LocationOnIcon sx={{ color: "secondary.main" }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      textTransform="uppercase"
                    >
                      Bengaluru, Karnataka, India
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item>
                    <EmailIcon sx={{ color: "secondary.main" }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      textTransform="lowercase"
                    >
                      support@mindsclik.com
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item>
                    <WifiCalling3Icon sx={{ color: "secondary.main" }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      textTransform="lowercase"
                    >
                     +918105057585
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Main>
  );
};

export default Support;

import React from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  IconButton,
  Card,
  CardMedia,
  Tooltip,
  Container
} from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import Carousel from "src/components/Carousel";

import CorouselData from "./corouselData";

const HeroContent = () => {

  return (
    <Grid
      sx={{
        backgroundImage:{xs:`url("/assets/img/hero_mobile.png")`,sm:`url("/assets/img/hero_mobile.png")`,md:`url("/assets/img/revoltion_bg.png")`},
        backgroundPosition: "center",
        backgroundSize:{xs:"contain",sm:"contain",md:"cover"},
        backgroundRepeat: "no-repeat",
        height: {xs:"80vh",sm:"80vh",md:"100vh"},
      }} 
    >
      <Container>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems={{xs:'center',sm:'center',md:'flex-end'}}
        >
          <Grid>
            <Typography
              variant="h1"
              color="text.secondary"
              textTransform="uppercase"
              textAlign="center"
            >
              Revolutionize
            </Typography>
          </Grid>
          <Grid>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              textTransform="uppercase"
              textAlign="center"
            >
              your business by taking it online in just a few hours
            </Typography>
          </Grid>
          <Grid>
            <Tooltip title=" Click to watch video">
              <IconButton
                aria-label="play/pause"
                sx={{
                  bgcolor: "secondary.main",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                <PlayArrow
                  sx={{ height: 25, width: 25, color: "secondary.light" }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          {/* <Grid>
             <Box sx={{backgroundImage: `url("/assets/img/Blue.png"")` }}>

             </Box>
          </Grid> */}
        </Grid>
        <Grid container spacing={2} sx={{display:{xs:"none",sm:"none",md:"flex"}}}>
        <Grid item xs={12} sm={12} md={6}></Grid>
        <Grid item  xs={12} sm={12} md={6}>
                <Carousel imageData={CorouselData}/>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default HeroContent;


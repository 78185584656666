import React from "react";
import {  AppBar, Container } from "@mui/material";
import TopToolbar from "./components/Toolbar/Toolbar";

export default function Topbar() {
  return (
    <AppBar position="fixed" sx={{ boxShadow: "none" }}>
      <Container>
        <TopToolbar />
      </Container>
    </AppBar>
  );
}

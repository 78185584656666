import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import * as Yup from "yup";

import contactservices from "src/services/contactServices";

export default function RegisterFormDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      //name: "",
      businessname: "",
      mobileno: "",
      whatsappmobileno: "",
      helplinemobileno: "",
      email: "",
      gstnno: "",
      address: "",
    },

    validationSchema: Yup.object({
      businessname: Yup.string()
        .max(50, "Must be 15 characters or less")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      mobileno: Yup.string()
        .min(10, "Must be 10 characters")
        .max(10, "Must be 10 characters ")
        .required("Required"),
      whatsappmobileno: Yup.string()
        .min(10, "Must be 10 characters")
        .max(10, "Must be 10 characters ")
        .required("Required"),
      helplinemobileno: Yup.string()
        .min(10, "Must be 10 characters")
        .max(10, "Must be 10 characters ")
        .required("Required"),
      gstnno: Yup.string()
        .min(15, "Must be 15 characters")
        .max(15, "Must be 15 characters ")
        .required("Required"),
      address: Yup.string()
        .max(150, "Must be 150 characters or less")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        await contactservices.register(values);
        handleClose();
      } catch (error) {
        console.log("Form: error", error);
      }
    },
  });

  return (
    <div>
      <Button
        variant="outlined"
        sx={{
          bgcolor: "secondary.main",
          color: "text.main",
          width: "120px",
          borderRadius: "60px",
        }}
        onClick={handleClickOpen}
      >
        Register
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle variant="featureTitle" color={"text.secondary"}>
          Register
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText variant="featureSubtitle" color={"text.main"}>
              To register for the solution, please share your contact details
              and business name. Team will reach out soon.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <TextField
                  autoFocus
                  margin="dense"
                  id="businessname"
                  label="Business Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.businessname}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <TextField
                  autoFocus
                  margin="dense"
                  id="mobileno"
                  label="Mobile No."
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.mobileno}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                />
                {formik.touched.mobileno && formik.errors.mobileno ? (
                  <span style={{ color: "red" }}>{formik.errors.mobileno}</span>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <TextField
                  autoFocus
                  margin="dense"
                  id="whatsappmobileno"
                  label="WhatsApp Mobile No."
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.whatsappmobileno}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                />
                {formik.touched.whatsappmobileno &&
                formik.errors.whatsappmobileno ? (
                  <span style={{ color: "red" }}>
                    {formik.errors.whatsappmobileno}
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="helplinemobileno"
                  label="Helpline Mobile No."
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.helplinemobileno}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                />
                {formik.touched.helplinemobileno &&
                formik.errors.helplinemobileno ? (
                  <span style={{ color: "red" }}>
                    {formik.errors.helplinemobileno}
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <TextField
                  autoFocus
                  margin="dense"
                  id="gstnno"
                  label="GSTN no"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.gstnno}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                />
                {formik.touched.gstnno && formik.errors.gstnno ? (
                  <span style={{ color: "red" }}>{formik.errors.gstnno}</span>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                />
                {formik.touched.email && formik.errors.email ? (
                  <span style={{ color: "red" }}>{formik.errors.email}</span>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="address"
                  label="Register Address"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  multiline
                  rows={2}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                bgcolor: "secondary.main",
                color: "text.main",
                width: "120px",
                borderRadius: "60px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                bgcolor: "secondary.main",
                color: "text.main",
                width: "120px",
                borderRadius: "60px",
              }}
              type="submit"
            >
              Register
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

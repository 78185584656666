import { React, useState } from "react";
import { Typography, Grid, TextField, Button } from "@mui/material";

//formik import
import { useFormik } from "formik";
import * as Yup from "yup";

import { useLocation, useNavigate } from "react-router-dom";
import authservices from "src/services/authServices";

const UserDeleteForm = ({appname}) => {
  const navigate = useNavigate();

  //change form functionlity
  const [send, setSend] = useState(true);

  const handleChange = () => {
    setSend(!send);
  };

  const formik = useFormik({
    initialValues: {
      businessName: appname,
      mobileno: "",
      otp: "",
    },

    validationSchema: Yup.object({
      mobileno: Yup.string()
        .max(10, "Must be 10 characters or less")
        .matches(/^[0-9]*$/, "Please enter valid no.")
        .required("Required"),
      otp: Yup.string()
        .max(6, "Must 6 characters or less")
        .matches(/^[0-9]*$/, "Please enter valid no."),
      // .required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        if (send) {
          await authservices.authMobile(values);
          console.log("Form: error", values);
          handleChange();
        } else {
          await authservices.authMobileWithOtp(values);
        }
      } catch (error) {
        // console.log("Form: error", error);
        console.log("form is not submitted");
      }
    },
  });
  return (
    <>
      <Typography variant="h4" color={"text.secondary"}>
        Delete My Account
      </Typography>
      <Typography variant="subtitle1" color={"text.main"}>
        App Name : {appname}
      </Typography>
      {send ? (
        <Grid>
          <Grid
            sx={{
              pt: "15px",
              // color:'#ffffff'
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    helperText
                    id="mobileno"
                    label="Mobile No"
                    name="mobileno"
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.mobileno}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                    inputProps={{
                      style:{color: "white"}
                    }}
                  />
                  {formik.errors.mobileno && formik.touched.mobileno ? (
                    <span style={{ color: "red" }}>
                      {formik.errors.mobileno}
                    </span>
                  ) : null}
                </Grid>
              </Grid>
              <Grid sx={{ pt: "16px" }}>
                <Button
                  variant="outlined"
                  type="submit"
                  sx={{
                    borderColor: "secondary.main",
                    color: "secondary.light",
                    borderRadius: "23px",
                  }}
                >
                  Send delete otp
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          <Grid
            sx={{
              pt: "15px",
              // color:'#ffffff'
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        helperText
                        id="mobileno"
                        label="Mobile No"
                        name="mobileno"
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.mobileno}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                        inputProps={{
                          style:{color: "white"}
                        }}
                      />
                      {formik.errors.mobileno && formik.touched.mobileno ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.mobileno}
                        </span>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        helperText
                        id="otp"
                        label="OTP"
                        name="otp"
                        variant="outlined"
                        required
                        fullWidth
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.otp}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                        inputProps={{
                          style:{color: "white"}
                        }}
                      />
                      {formik.errors.otp && formik.touched.otp ? (
                        <span style={{ color: "red" }}>
                          {formik.errors.otp}
                        </span>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="subtitle1" color={"text.main"}>
                        Note : Once the account is delete you will loose all
                        your access to the application and the data will be
                        removed permanently from the application.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ pt: "16px" }}>
                <Button
                  variant="outlined"
                  type="submit"
                  sx={{
                    borderColor: "secondary.main",
                    color: "secondary.light",
                    borderRadius: "23px",
                  }}
                >
                  delete
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={() => navigate("/home")}
                  sx={{
                    borderColor: "secondary.main",
                    color: "secondary.light",
                    borderRadius: "23px",
                    ml: 2,
                  }}
                >
                  cancel
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default UserDeleteForm;

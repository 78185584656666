import React from "react";
import { Box, Card, CardContent, Container } from "@mui/material";

import { useParams } from 'react-router-dom';

//project imports
import Main from "src/layout/Main";
import UserSendForm from "./UserForms/UserDeleteForm";

const UserPage = () => {
  const { appname } = useParams();
  console.log("Delete AppName: ", appname);



  return (
    <Main>
      <Container>
        <Box pt={20} sx={{ flexGrow: 1 }}>
          <Card sx={{border:'solid',color:"secondary.main"}}> 
            <CardContent>
                <UserSendForm appname={appname}/>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Main>
  );
};

export default UserPage;

// ?appName=AppName

import * as React from "react";
import {
  Box,
  Drawer,  
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";


const drawerWidth = 240;


function Sidebar({children},props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };



  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: { sm: "none" } }}>
       <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon fontSize="large"/>
          </IconButton>
          <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {children}
        </Drawer>
      </Box>
    </Box>
  );
}


export default Sidebar;
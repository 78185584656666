import React from "react";
import { Grid } from "@mui/material";
import Topbar from "./components/Topbar/Topbar";

import PropTypes from "prop-types";
import Footer from "./components/footer/footer";

const Main = ({ children }) => {
  return (
    <Grid>
        <Topbar />
        <main>{children}</main>
        {/* <Divider/> */}
        <Footer/>
    </Grid>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
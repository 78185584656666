
const CorouselData = [
    {
      id: 1,
      img: "/assets/img/chillyandvanilla.png",
    },
    {
      id: 2,
      img: "/assets/img/Hs.png",
    },
  ];


export default CorouselData;